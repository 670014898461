<template>
    <results-layout>
        <v-navigation-drawer permanent left class="d-flex nav-drawer" clipped width="350" app>
            <div class="pt-12 pb-4 px-6">
                <div class="mt-6 text-subtitle-1">Form Questions</div>
            </div>
            <div class="pt-4 px-6 pb-12 questions-list-container">
                <template v-if="[STATES.PENDING].includes(state)">
                    <div v-for="key in 10" :key="`placeholder-${key}`" class="my-2">
                        <skeleton-loader-vue
                            type="rect"
                            :width="268"
                            :height="62"
                            animation="fade"
                            rounded
                            radius="4"
                        />
                    </div>
                </template>
                <template v-else-if="questionsListData && questionsListData.length">
                    <div v-for="(question, index) in questionsListData" :key="question.id">
                        <v-card 
                            v-if="question.type==='description-slide'"
                            class="pa-4 mb-6"
                            outlined 
                            :color="$route.params.questionId == question.id? 'primary': ''"
                            :dark="$route.params.questionId == question.id"
                            :key="question.id"
                            style="border-width: 0px"
                            disabled
                        >
                            <div class="d-flex flex-grow-1">
                                <div class="d-flex align-center question-type-badge">
                                    <v-icon size="16" color="primary darken-1" >{{ questionIcons[question.type] }}</v-icon>
                                    <div class="text-body-2">{{index+1}}</div>
                                </div>
                                <div class="text-caption flex-grow-1 px-4">
                                    Description Slide
                                </div>
                            </div>
                        </v-card>
                        <v-badge 
                            v-else
                            overlap
                            style="width:100%;"
                            bordered
                            :value="question.answer_count"
                            :content="question.answer_count"
                            :key="question.id"
                        >
                            <v-card 
                                class="pa-4 mb-6"
                                outlined 
                                :to="{
                                    path: `/dashboard/voiceform/${$route.params.surveyId}/results/questions/${question.id}`
                                }"
                                :color="$route.params.questionId == question.id? 'primary': ''"
                                :dark="$route.params.questionId == question.id"
                            >
                                <div class="d-flex flex-grow-1">
                                    <div class="d-flex align-center question-type-badge">
                                        <v-icon size="16" color="primary darken-1" >{{ questionIcons[question.type] }}</v-icon>
                                        <div class="text-body-2">{{index+1}}</div>
                                    </div>
                                    <div class="question-title text-caption flex-grow-1 px-4">
                                        {{question.text || question.type}}
                                    </div>
                                </div>
                            </v-card>
                        </v-badge>
                    </div>
                </template>
                <v-card v-else outlined class="pa-4" color="grey lighten-4">
                    <div class="text-body-1">
                        Start adding questions to your voiceform!
                    </div>
                    <router-link :to="{
                                name: 'dashboard.voiceform.show',
                                params: {
                                    surveyId: $route.params.surveyId
                                }
                            }">
                    <v-btn class="ml-n4 mt-6" color="secondary" text>
                        <v-icon left>mdi-plus</v-icon>
                        Add question
                    </v-btn>
                    </router-link>
                </v-card>
            </div>
        </v-navigation-drawer>
        <div class="mt-4" >
            <question-details :key="$route.params.surveyId" v-if="selectedQuestion" :question="selectedQuestion"></question-details>
            <div style="padding-top: 120px" v-else>
                <no-questions></no-questions>
            </div>
        </div>
        <v-navigation-drawer :dark="true" v-model="openFileViewer" color="grey darken-4" right class="d-flex nav-drawer" fixed temporary width="512" >
            <div class="d-flex align-center pa-4">
                <div class="text-body-1 white--text">File Preview</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="openFileViewer = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <file-viewer></file-viewer>
        </v-navigation-drawer>
    </results-layout>
</template>

<script>
import {mapMutations } from 'vuex';
import { questionIcons } from '../../../../configs/questionTypes';
import ResultsLayout from '../../../../layouts/ResultsLayout.vue';
import QuestionDetails from './QuestionDetails.vue';
import NoQuestions from '../results/NoQuestions.vue';
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '../../../../api/fetcher';
import useSwrvState from '@/utils/useSwrvState'
import FileViewer from './FileViewer.vue';

export default {
    data() {
        return {
            questionIcons,
            providedQuestion: {},
        }
    },
    provide() {
        return {
            question: this.providedQuestion,
        }
    },
    components: { ResultsLayout, QuestionDetails, NoQuestions, FileViewer },
    watch: {
        'questionsListData': {
            immediate: true,
            handler() {
                if (this.$route.name === 'dashboard.voiceform.results.questions' && this.questionsListData && this.questionsListData.length && this.$route.params && this.$route.params.surveyId && !this.$route.params.questionId) {
                    this.$router.replace({
                        name: 'dashboard.voiceform.results.questions', 
                        params: {
                            surveyId: this.$route.params.surveyId,
                            questionId: this.questionsListData[0].id
                        }
                    })
                }
            }
        },
        'selectedQuestion': {
            handler: function () {
                Object.assign(this.providedQuestion, this.selectedQuestion)
            },
            immediate: true,
        },
    },
    computed:{
        selectedQuestion(){
            return this.$route.params.questionId && this.questionsListData &&this.questionsListData.find(question => question.id === this.$route.params.questionId) || null;
        },
        openFileViewer: {
            get(){
                return !!this.$route.query.fileViewerUrl;
            },
            set(value){
                if(!value){
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            fileViewerUrl: null
                        }
                    })
                }
            }
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),

        async copyQuestionResultLink(questionId) {
            try {
                await navigator.clipboard.writeText(window.location.origin + "/dashboard/voiceform/"
                    + this.$route.params.surveyId + "/results/questions/" + questionId);
            } catch (err) {
                console.error(err);
            }
            this.showSnackBar({
                text: "Question result link copied.",
                color: "success",
                timeout: 1000,
            });
        },
    },
    setup(){
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(`/api/analytics/${route.params.surveyId}/questions`, fetcher);
        const { state, STATES } = useSwrvState(data, error, isValidating)
        return {state, STATES, questionsListData:data, error, isValidating, revalidateQuestionsList:mutate };
    }
}
</script>

<style lang="scss" scoped>
.nav-drawer::v-deep .v-navigation-drawer__content{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.questions-list-container{
    overflow-y: scroll;
    width: 100%;
    flex: 1;
}
.question-type-badge{
    width: 52px;
    height: 24px;
    min-width: 52px;
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
}
.question-title {
    min-height: 3em;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 150%;
}
</style>