var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('results-layout',[_c('v-navigation-drawer',{staticClass:"d-flex nav-drawer",attrs:{"permanent":"","left":"","clipped":"","width":"350","app":""}},[_c('div',{staticClass:"pt-12 pb-4 px-6"},[_c('div',{staticClass:"mt-6 text-subtitle-1"},[_vm._v("Form Questions")])]),_c('div',{staticClass:"pt-4 px-6 pb-12 questions-list-container"},[([_vm.STATES.PENDING].includes(_vm.state))?_vm._l((10),function(key){return _c('div',{key:("placeholder-" + key),staticClass:"my-2"},[_c('skeleton-loader-vue',{attrs:{"type":"rect","width":268,"height":62,"animation":"fade","rounded":"","radius":"4"}})],1)}):(_vm.questionsListData && _vm.questionsListData.length)?_vm._l((_vm.questionsListData),function(question,index){return _c('div',{key:question.id},[(question.type==='description-slide')?_c('v-card',{key:question.id,staticClass:"pa-4 mb-6",staticStyle:{"border-width":"0px"},attrs:{"outlined":"","color":_vm.$route.params.questionId == question.id? 'primary': '',"dark":_vm.$route.params.questionId == question.id,"disabled":""}},[_c('div',{staticClass:"d-flex flex-grow-1"},[_c('div',{staticClass:"d-flex align-center question-type-badge"},[_c('v-icon',{attrs:{"size":"16","color":"primary darken-1"}},[_vm._v(_vm._s(_vm.questionIcons[question.type]))]),_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(index+1))])],1),_c('div',{staticClass:"text-caption flex-grow-1 px-4"},[_vm._v(" Description Slide ")])])]):_c('v-badge',{key:question.id,staticStyle:{"width":"100%"},attrs:{"overlap":"","bordered":"","value":question.answer_count,"content":question.answer_count}},[_c('v-card',{staticClass:"pa-4 mb-6",attrs:{"outlined":"","to":{
                                path: ("/dashboard/voiceform/" + (_vm.$route.params.surveyId) + "/results/questions/" + (question.id))
                            },"color":_vm.$route.params.questionId == question.id? 'primary': '',"dark":_vm.$route.params.questionId == question.id}},[_c('div',{staticClass:"d-flex flex-grow-1"},[_c('div',{staticClass:"d-flex align-center question-type-badge"},[_c('v-icon',{attrs:{"size":"16","color":"primary darken-1"}},[_vm._v(_vm._s(_vm.questionIcons[question.type]))]),_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(index+1))])],1),_c('div',{staticClass:"question-title text-caption flex-grow-1 px-4"},[_vm._v(" "+_vm._s(question.text || question.type)+" ")])])])],1)],1)}):_c('v-card',{staticClass:"pa-4",attrs:{"outlined":"","color":"grey lighten-4"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(" Start adding questions to your voiceform! ")]),_c('router-link',{attrs:{"to":{
                            name: 'dashboard.voiceform.show',
                            params: {
                                surveyId: _vm.$route.params.surveyId
                            }
                        }}},[_c('v-btn',{staticClass:"ml-n4 mt-6",attrs:{"color":"secondary","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add question ")],1)],1)],1)],2)]),_c('div',{staticClass:"mt-4"},[(_vm.selectedQuestion)?_c('question-details',{key:_vm.$route.params.surveyId,attrs:{"question":_vm.selectedQuestion}}):_c('div',{staticStyle:{"padding-top":"120px"}},[_c('no-questions')],1)],1),_c('v-navigation-drawer',{staticClass:"d-flex nav-drawer",attrs:{"dark":true,"color":"grey darken-4","right":"","fixed":"","temporary":"","width":"512"},model:{value:(_vm.openFileViewer),callback:function ($$v) {_vm.openFileViewer=$$v},expression:"openFileViewer"}},[_c('div',{staticClass:"d-flex align-center pa-4"},[_c('div',{staticClass:"text-body-1 white--text"},[_vm._v("File Preview")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.openFileViewer = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('file-viewer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }