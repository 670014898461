<template>
    <div v-if="fileViewerType" class="d-flex flex-grow-1 flex-column">
        <div class="d-flex flex-grow-1 grey darken-3">
            <!-- Show image when the type is image -->
            <v-img v-if="fileViewerType.includes('image')" :src="fileViewerUrl" contain></v-img>

            <!-- Show video when the type is video -->
            <video v-else-if="fileViewerType.includes('video')" :src="fileViewerUrl" controls width="100%" height="100%">
            </video>

            <iframe v-else-if="fileViewerType.includes('pdf') || fileViewerType.includes('word')" 
            :src="'https://docs.google.com/gview?url=' + fileViewerUrl + '&embedded=true'" 
            style="width: 100%; height: 100%; border: none;"></iframe>

            <!-- Show 'preview not available' for other file types -->
            <div v-else class="d-flex align-items-center justify-content-center" style="width: 100%; height: 100%;">
                Preview not available
            </div>
        </div>
        <div class="pa-4">
            <v-btn @click="downloadFile" :loading="downloading" :disabled="downloading" height="55" elevation="0" color="primary" width="100%" >
                <v-icon left>mdi-download</v-icon>
                Download File
            </v-btn>
        </div>
    </div>
</template>

<script>
import DownloadApi from '@/api/DownloadApi';
import { mapMutations } from 'vuex';

export default {
    data(){
        return {
            downloading: false
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async downloadFile(){
            try {
                this.downloading = true;
                const response = await DownloadApi.download(this.fileViewerUrl);
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                
                // Extracting filename from the Content-Disposition header (optional)
                const contentDisposition = response.headers['content-disposition'];
                let filename = 'downloaded_file';
                if (contentDisposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                
                link.download = filename;
                link.click();
                URL.revokeObjectURL(link.href);
            } catch (error) {
                console.error(error);
            }finally{
                this.downloading = false;
            }
        }
    },
    computed: {
        fileViewerUrl(){
            return this.$route.query.fileViewerUrl;
        },
        fileViewerType(){
            return this.$route.query.fileViewerType;
        },
        fileViewerName(){
            return this.$route.query.fileViewerName;
        }
    }
}
</script>
